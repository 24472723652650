import React, { useEffect, useState } from 'react';

export default function Menu() {
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        
    }, {})
    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo">
                <a href="/home" className="app-brand-link">
                    {/* <span className="app-brand-logo demo">
                        <img style={{ width: '80%' }} src="https://entregaja.com.br/assets/images/logo/logo.png" />
                    </span> */}
                    <span className="app-brand-text demo menu-text fw-bolder ms-2">TvCombate</span>
                </a>
                <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                    <i className="bx bx-chevron-left bx-sm align-middle" />
                </a>
            </div>
            <div className="menu-inner-shadow" />
            <ul className="menu-inner py-1">
                <li className="menu-item active">
                    <a href="/admin" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-circle" />
                        <div data-i18n="Analytics">Dashboard</div>
                    </a>
                </li>
                <li className="menu-item">
                    <a href="/eventos" className="menu-link menu-toggle">
                        <i className="menu-icon tf-icons bx bx-home" />
                        <div data-i18n="Account Settings">Eventos</div>
                    </a>
                </li>
                <li className="menu-item">
                    <a href="/usuarios" className="menu-link menu-toggle">
                        <i className="menu-icon tf-icons bx bx-user" />
                        <div data-i18n="Account Settings">Usuarios</div>
                    </a>
                </li>
                <li className="menu-item">
                    <a href="/pagamentos" className="menu-link menu-toggle">
                        <i className="menu-icon tf-icons bx bx-money" />
                        <div data-i18n="Account Settings">Pagamentos</div>
                    </a>
                </li>

            </ul>

        </aside>
    );
}

