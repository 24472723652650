import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaEventos } from '../../../services/mysql'

export default function Atleta() {
    const [academias, setAcademias] = useState([])
    const [videos, setVideos] = useState([])
    const [search, setSearch] = useState('')

    useEffect(() => {
        mensagemLoading('Buscando Academias...')
        listaEventos().then((res) => {
            Swal.close()
            setAcademias(res)
        })
    }, {})

    function detalheVideo(data) {
        window.location = `/videosAtleta?i=${data.id}&&a=${data.nome}`
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            mensagemLoading('Atualizando Academias...')
            listaEventos().then((res) => {
                Swal.close()
                setAcademias(res)
            })
        })
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h3><b>Eventos</b></h3>
                                {academias && academias.length > 0 ?
                                    <div className="row">
                                        {academias.map((academia) =>
                                            <div className="col-sm-6 col-lg-6 mb-4">
                                                <div className="card card-border-shadow-primary h-100">
                                                    <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        <img style={{ width: 100, height: 100 }} src={academia.imagem} />
                                                        <h5 style={{ marginTop: 20 }}>{academia.nome}</h5>
                                                        <p className="mb-1">{academia.descricao}</p>
                                                        <button onClick={() => detalheVideo(academia)} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver vídeos</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}