import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Site
import Login from './pages/Login'
import Cadastro from './pages/Cadastro';
import EsqueciSenha from './pages/EsqueciSenha';

// Admin
import Admin from './pages/Admin'

// Academias
import Eventos from './pages/Eventos';
import CadastroEventos from './pages/Eventos/Cadastro';

// Atleta
import HomeAtleta from './pages/Atleta/Home';
import VideosAtleta from './pages/Atleta/Videos';
import TrocaSenha from './pages/Atleta/TrocaSenha';

// Usuários
import Usuarios from './pages/Usuarios';

// Pagamentos
import Pagamentos from './pages/Pagamentos';

export default function Rotas() {
    return (
        <div className="App">
            <BrowserRouter>

                {/* Auth */}
                <Route path="/" exact={true} component={Login} />
                <Route path="/cadastro" exact={true} component={Cadastro} />
                <Route path="/esqueciSenha" exact={true} component={EsqueciSenha} />

                {/* Admin */}
                <Route path="/admin" exact={true} component={Admin} />

                {/* Academias */}
                <Route path="/eventos" exact={true} component={Eventos} />
                <Route path="/cadastroEvento" exact={true} component={CadastroEventos} />

                {/* Atletas */}
                <Route path="/homeAtleta" exact={true} component={HomeAtleta} />
                <Route path="/videosAtleta" exact={true} component={VideosAtleta} />
                <Route path="/trocaSenha" exact={true} component={TrocaSenha} />

                {/* Usuários */}
                <Route path="/usuarios" exact={true} component={Usuarios} />

                {/* Pagamentos */}
                <Route path="/pagamentos" exact={true} component={Pagamentos} />

            </BrowserRouter>
        </div>
    );
}