import React, { useEffect, useState } from 'react';

export default function App() {
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userd7'))
        if (user) {
            setUsuario(user)
        }
    }, {})

    function logout() {
        localStorage.setItem('userd7', null)
        window.location = '/'
    }
    return (
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                    <i className="bx bx-menu bx-sm" />
                </a>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <ul className="navbar-nav flex-row align-items-center ms-auto">
                    {/* Place this tag where you want the button to render. */}
                    <li className="nav-item lh-1 me-3">
                        {usuario.nome}
                    </li>
                    {/* User */}
                    <li className="nav-item navbar-dropdown dropdown-user dropdown">
                        <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                            <div className="avatar avatar-online">
                                <img style={{ width: 20, height: 20 }} src={"https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"} alt className="w-px-40 h-auto rounded-circle" />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <a onClick={() => window.location = '/trocaSenha?i=' + usuario.id} className="dropdown-item" href="javascript:;">
                                    <i className="bx bx-key me-2" />
                                    <span className="align-middle">Trocar Senha</span>
                                </a>
                                <a onClick={() => logout()} className="dropdown-item" href="javascript:;">
                                    <i className="bx bx-power-off me-2" />
                                    <span className="align-middle">Sair</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    {/*/ User */}
                </ul>
            </div>
        </nav>

    );
}

