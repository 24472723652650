import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { login } from '../../services/mysql'

export default function Login() {
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [mostrarSenha, setMostrarSenha] = useState(false);

    function realizaLogin() {
        if (!email) {
            mensagemAlerta('Digite seu Email')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite sua senha')
            return
        }
        mensagemLoading('Buscando cadastrado..')
        const data = {
            email: email,
            senha: senha
        }
        login(data).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemAlerta(res.message)
            } else {
                localStorage.setItem('userd7', JSON.stringify(res.user));
                window.location = res.user.perfil === 'ADMIN' ? '/admin' : 'homeAtleta'
            }
        })

    }
    const toggleMostrarSenha = () => {
        setMostrarSenha((prevMostrarSenha) => !prevMostrarSenha);
    };

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                            <img onClick={() => window.location.href = 'https://tvcombate.com.br'} src='/images/tvcombate_logo_login.png' style={{ width: '100%' }} />
                            <h4 className="mb-2" style={{ marginTop: 20, textAlign: 'center' }}>Bem Vindo</h4>
                            <p className="mb-4" style={{ textAlign: 'center' }}>Login</p>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" value={email} onChange={handleEmail} className="form-control" id="email" name="email-username" placeholder="Digite seu Email" autofocus />
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">Senha</label>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input value={senha} onChange={handleSenha} type={mostrarSenha ? 'text' : 'password'} id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                        <span className="input-group-text cursor-pointer"><i className="bx bx-hide" onClick={toggleMostrarSenha} /></span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button onClick={() => realizaLogin()} className="btn btn-primary d-grid w-100" type="submit">Acessar</button>
                                    <button onClick={() => window.location = '/cadastro'} className="btn btn-default d-grid w-100" type="submit">Novo Cadastro</button>
                                    <button onClick={() => window.location = '/esqueciSenha'} className="btn btn-default d-grid w-100" type="submit">Esqueci minha Senha</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}